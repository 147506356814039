<template>
    <div>
        <AuthForm :title="$t('forgotPassword.title')" :with-social="false">
            <template #inputs>
                <CRInput
                    class="input"
                    v-model="password"
                    :label="$t('common.newPassword')"
                    :placeholder="$t('placeholder.enterNewPassword')"
                    :errors="errors?.password"
                    @onChange="$filters.clearError(errors, 'password')"
                    type="password"
                />
                <CRInput
                    class="input"
                    v-model="passwordConfirmation"
                    :label="$t('common.confirmNewPassword')"
                    :placeholder="$t('common.confirmNewPassword')"
                    :errors="errors?.passwordConfirmation"
                    @onChange="$filters.clearError(errors, 'passwordConfirmation')"
                    type="password"
                />
            </template>
            <template #buttons>
                <CRButton @click="reset" :loading="isLoading"> {{ $t('buttons.resetPassword') }} </CRButton>
            </template>
        </AuthForm>
        <SuccessModal
            v-if="modalShown"
            @close="closeModal"
            :title="$t('forgotPassword.passwordChanged')"
            :description="$t('forgotPassword.yourPasswordSuccessfullyChanged')"
        />
    </div>
</template>

<script>
    import SuccessModal from '@/components/Modals/SuccessModal.vue';
    import { ForgotPasswordApi } from '@/api';
    import AuthForm from '@/components/Forms/AuthForm.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';

    export default {
        name: 'ResetPassword',
        components: { CRInput, CRButton, AuthForm, SuccessModal },
        data() {
            return {
                password: '',
                passwordConfirmation: '',
                errors: {},

                modalShown: false,
                isLoading: false,
            };
        },
        methods: {
            async reset() {
                this.isLoading = true;

                const data = {
                    email: this.$route.query.email,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                    token: this.$route.query.token,
                };

                try {
                    await ForgotPasswordApi.reset(data);
                    this.modalShown = true;
                } catch (error) {
                    this.errors = error.errors;
                } finally {
                    this.isLoading = false;
                }
            },
            closeModal() {
                this.modalShown = false;
                this.$router.push({ name: 'login' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .input {
        margin-bottom: 20px;
    }

    .forgot-password-btn {
        margin-top: 3px;

        font-size: $font-12;
    }

    .tooltip {
        margin: 18px 0;

        font-style: italic;
        font-weight: 300;

        a {
            color: $primary;
            text-decoration: underline;
        }
    }

    .btn {
        margin-right: 10px;
    }
</style>
